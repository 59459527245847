import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import IvBottom from './components/ivBottom';
import Shop from './components/shop';
import Checkout from './components/checkout';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="*" element={<Shop />} />
          <Route path="/checkout/*" element={<Checkout />} />
          <Route path="/main/*" element={<Shop />} />
        </Routes>
      </Router>
      <IvBottom />
    </div>
  );
}

export default App;