import React from "react";
import Product from "./Product";

export default function Main(props) {
    const { products, onAdd } = props;
    return (
        <>
            <div className="wrapPageBackgroundDesktop" >
                <div className="wrapFormText">
                    <div className="wrapperBodyText">
                        <div className="bodyTextSignUp">Shoes That You’ll Love.
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapPageBackgroundMob" >
                <div className="wrapFormText">
                    <div className="wrapperBodyText">
                        <div className="bodyTextSignUp">Shoes That You’ll Love.
                        </div>
                    </div>
                </div>
            </div>

            <main className="blocks-items">
                <div className="rap-blocks-product">
                    <div className="rapText trending-top">Trending This Week</div>
                    {products.map((product) => (
                        <Product key={product.id} product={product} onAdd={onAdd} />
                    ))}
                </div>
            </main>
        </>
    );
}