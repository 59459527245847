import React from "react";
import {
    useNavigate
} from "react-router-dom";

export default function Basket(props) {
    const navigate = useNavigate();
    const { cartItems, onAdd, onRemove } = props;
    const itemsPrice = cartItems.reduce((a, c) => a + c.price * c.qty, 0);
    const totalPrice = itemsPrice;
    // const totalPrice = itemsPrice + discountItemPrice ---- for discount items soon
    const navigateToPaymentPage = () => {
        navigate("/checkout", {
            state: {
                totalPrice,
                cartItems,
            }
        });
    };

    return (
        <aside className="block col-1" style={{
            visibility: "hidden"
        }}>
            <div className="row">
                <button id="checkoutbtn" onClick={navigateToPaymentPage}></button>
            </div>
            <div>
                <h2>Cart Items</h2>

                {/* Display message when cartItemsLength is 0 */}
                <div>{cartItems.length === 0 && <div>Cart is Empty</div>} </div>

                {/* Renders the added item to the basket of the shopping cart through mapping cartItems */}
                {cartItems.map((item) => (
                    <div key={item.id} className="row">
                        <div className="col-2">
                            {item.name} -- ${item.price.toFixed(2)}
                        </div>

                        {/* Increment and Decrement Buttons */}
                        <div className="col-2">
                            <button onClick={() => onRemove(item)} className="remove">
                                -
                            </button>
                            <button onClick={() => onAdd(item)} className="add">
                                +
                            </button>
                            Qty: {item.qty}
                        </div>

                        <div className="col-2 text-right">
                            ${(item.price * item.qty).toFixed(2)}
                        </div>
                    </div>
                ))}

                {cartItems.length !== 0 && (
                    <>
                        <hr></hr>

                        <div className="row">
                            <div className="col-2">
                                <strong>Total Price</strong>
                            </div>
                            <div className="col-1 text-right">
                                <strong>${totalPrice.toFixed(2)}</strong>
                            </div>
                        </div>
                        <hr />
                    </>
                )}
            </div>
        </aside>
    );
}
