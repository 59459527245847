import '../css/vectors.css';
import { Link } from 'react-router-dom';
import '../css/vectors.css';

export default function HeaderText() {
    return (
        <div className="headerTop">
            <div className="wrapperHead">
                <div className="vectorsRap">
                    <div className="containerHead">
                        <div className="rapHeader">
                            <span className="headText1"><Link to="/">Shoesy</Link></span>
                        </div>
                    </div>
                    <Link to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" width="85" height="50" viewBox="0 0 85 70" fill="none">
                            <ellipse cx="43.5" cy="42" rx="41.5" ry="28" fill="#8FC7B8" />
                            <ellipse cx="32.5" cy="35" rx="32.5" ry="35" fill="#3A1447" />
                        </svg>
                    </Link>
                </div>

                <div className="rightFilter">
                    <div className="rapRightFilter">
                        <span className="rapText filter">Women</span>
                        <span className="rapText filter">Men</span>
                        <span className="rapText filter">Kids</span>
                    </div>
                </div>
            </div>
        </div>
    )
}