import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import HeaderText from './headerText';
import ErrorIcon from '../assets/error-icon.png';
import { PhoneInput } from 'react-international-phone';
import 'bootstrap/dist/css/bootstrap.min.css';
import PaymentPage from "./PaymentPage";
import '../css/signForm.css';
import '../css/headerText.css';

export default function Checkout(props) {

    const [phone, setPhone] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        lastname: '',
        email: '',
        address1: '',
        address2: '',
        postal1: '',
        postal2: '',
        country: '',
        creditName: '',
        experationMonth: '',
        experationYear: '',
        creditNumber: '',
        phone: '',
        cvv: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setSubmitted(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            formData.phone = document.querySelector('input.react-international-phone-input').value + document.getElementById('phone-input').value;
            document.querySelector(".rapformer").style.display = "none";
            setSubmitted(true);
            setError(false);
        } catch (error) {
            console.error('Error during POST request:', error.message);
            setError(true);
        }
    };

    const successMessage = () => {
        return (
            <div
                className="success success"
                style={{
                    display: submitted ? '' : 'none',
                }}>
                <div className="rapsum success">
                    <div className="rapText header success">Success!</div>
                    <ul className="listtext">
                        <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                            Full Payment
                            <span>${Math.floor(data.totalPrice * 0.2) + (data.totalPrice)}</span>
                        </li>
                        <hr className="list-hr" />
                    </ul>

                    <div className="rap-item-block-checkout">
                        <img className="imgCheckout" src={data.cartItems[0].image} alt={data.cartItems[0].name} width="180" height="180"></img>
                        <div className="rap-text">
                            <div className="item-text1">{data.cartItems[0].name}</div>
                            <div className="item-text2">{data.cartItems[0].discription}</div>
                            <div className="item-text3">Size: {data.cartItems[0].size}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const location = useLocation();
    const data = location.state;

    const errorMessage = () => {
        return (
            <div
                className="errorBox"
                style={{
                    display: error ? '' : 'none',
                }}>
                <div className="verifySvg">
                    <img className="error-icon" src={ErrorIcon} alt={"error-icon"} />
                </div>
                <div className="verifyText">
                    Failed. Please try again
                </div>
            </div>
        );
    };

    window.onclick = e => {
        if (document.querySelector("button.react-international-phone-country-selector-button--active") != null) {
            document.querySelector("foreignObject#svg").style.height = "400px";
            document.querySelector("svg#svg").style.height = "400px";
            document.querySelector("svg#svg").style.marginTop = "-160px";
        }
        else if ((window.location.href.indexOf("checkout") > -1) && (document.querySelector("button.react-international-phone-country-selector-button--active") == null)) {
            document.querySelector("foreignObject#svg").style.height = "59px";
            document.querySelector("svg#svg").style.height = "59px";
            document.querySelector("svg#svg").style.marginTop = "0px";
        }
    }

    return (
        <>
            <div className="wrapPageForm">
                <HeaderText />

                <div className="messages">
                    {errorMessage()}
                    {successMessage()}
                </div>
            </div >

            <div className="rapformer">
                <div className="main-rap2">
                    <div className="cardy mb-4">

                        <div className="form-rap">
                            <form onSubmit={handleSubmit}>
                                <div className="rapText header">Your Name and address</div>
                                <div className="row mb-4">

                                    <div className="col">
                                        <label className="labelOver">First Name</label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="282" height="59" viewBox="0 0 282 59" fill="none">
                                            <path fillule="evenodd" clipRule="evenodd" d="M4.7655 1H277.234C279.306 1 281 3.40851 281 6.3532V52.6468C281 55.5908 279.306 58 277.234 58H4.7655C2.69463 58 1 55.5915 1 52.6468V6.3532C1 3.40851 2.69418 1 4.7655 1Z" stroke="#8FC7B8" />
                                            <foreignObject width="282" height="59">
                                                <input onChange={handleInputChange} className="input" value={formData.name} type="text" name="name" required />
                                            </foreignObject>
                                        </svg>
                                    </div>
                                    <div className="col">
                                        <label className="labelOver">Last Name</label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="282" height="59" viewBox="0 0 282 59" fill="none">
                                            <path fillule="evenodd" clipRule="evenodd" d="M4.7655 1H277.234C279.306 1 281 3.40851 281 6.3532V52.6468C281 55.5908 279.306 58 277.234 58H4.7655C2.69463 58 1 55.5915 1 52.6468V6.3532C1 3.40851 2.69418 1 4.7655 1Z" stroke="#8FC7B8" />
                                            <foreignObject width="282" height="59">
                                                <input onChange={handleInputChange} className="input" value={formData.lastname} type="text" name="lastname" required />
                                            </foreignObject>
                                        </svg>
                                    </div>
                                </div>

                                <div className="row mb-4">

                                    <div className="col">
                                        <label className="labelOver">Address line 1</label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="282" height="59" viewBox="0 0 282 59" fill="none">
                                            <path fillule="evenodd" clipRule="evenodd" d="M4.7655 1H277.234C279.306 1 281 3.40851 281 6.3532V52.6468C281 55.5908 279.306 58 277.234 58H4.7655C2.69463 58 1 55.5915 1 52.6468V6.3532C1 3.40851 2.69418 1 4.7655 1Z" stroke="#8FC7B8" />
                                            <foreignObject width="282" height="59">
                                                <input onChange={handleInputChange} className="input" value={formData.address1} type="text" name="address1" required />
                                            </foreignObject>
                                        </svg>
                                    </div>
                                    <div className="col">
                                        <label className="labelOver">Address line 2</label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="282" height="59" viewBox="0 0 282 59" fill="none">
                                            <path fillule="evenodd" clipRule="evenodd" d="M4.7655 1H277.234C279.306 1 281 3.40851 281 6.3532V52.6468C281 55.5908 279.306 58 277.234 58H4.7655C2.69463 58 1 55.5915 1 52.6468V6.3532C1 3.40851 2.69418 1 4.7655 1Z" stroke="#8FC7B8" />
                                            <foreignObject width="282" height="59">
                                                <input onChange={handleInputChange} className="input" value={formData.address2} type="text" name="address2" required />
                                            </foreignObject>
                                        </svg>
                                    </div>
                                </div>

                                <div className="row mb-4">

                                    <div className="col">
                                        <label className="labelOver">Postal Code</label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="282" height="59" viewBox="0 0 282 59" fill="none">
                                            <path fillule="evenodd" clipRule="evenodd" d="M4.7655 1H277.234C279.306 1 281 3.40851 281 6.3532V52.6468C281 55.5908 279.306 58 277.234 58H4.7655C2.69463 58 1 55.5915 1 52.6468V6.3532C1 3.40851 2.69418 1 4.7655 1Z" stroke="#8FC7B8" />
                                            <foreignObject width="282" height="59">
                                                <input onChange={handleInputChange} className="input" value={formData.postal1} type="text" name="postal1" required />
                                            </foreignObject>
                                        </svg>
                                    </div>
                                    <div className="col">
                                        <label className="labelOver">Postal Code</label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="282" height="59" viewBox="0 0 282 59" fill="none">
                                            <path fillule="evenodd" clipRule="evenodd" d="M4.7655 1H277.234C279.306 1 281 3.40851 281 6.3532V52.6468C281 55.5908 279.306 58 277.234 58H4.7655C2.69463 58 1 55.5915 1 52.6468V6.3532C1 3.40851 2.69418 1 4.7655 1Z" stroke="#8FC7B8" />
                                            <foreignObject width="282" height="59">
                                                <input onChange={handleInputChange} className="input" value={formData.postal2} type="text" name="postal2" />
                                            </foreignObject>
                                        </svg>
                                    </div>
                                </div>

                                <div className="row mb-4">

                                    <div className="col">
                                        <label className="labelOver">Country</label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="282" height="59" viewBox="0 0 282 59" fill="none">
                                            <path fillule="evenodd" clipRule="evenodd" d="M4.7655 1H277.234C279.306 1 281 3.40851 281 6.3532V52.6468C281 55.5908 279.306 58 277.234 58H4.7655C2.69463 58 1 55.5915 1 52.6468V6.3532C1 3.40851 2.69418 1 4.7655 1Z" stroke="#8FC7B8" />
                                            <foreignObject width="282" height="59">
                                                <input onChange={handleInputChange} className="input" value={formData.country} type="text" name="country" required />
                                            </foreignObject>
                                        </svg>
                                    </div>
                                </div>

                                <hr className="linebrake" />

                                <div className="rapText header">Contact Info</div>
                                <div className="row mb-4">
                                    <div className="col">
                                        <label className="labelOver">Email</label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="282" height="59" viewBox="0 0 282 59" fill="none">
                                            <path fillule="evenodd" clipRule="evenodd" d="M4.7655 1H277.234C279.306 1 281 3.40851 281 6.3532V52.6468C281 55.5908 279.306 58 277.234 58H4.7655C2.69463 58 1 55.5915 1 52.6468V6.3532C1 3.40851 2.69418 1 4.7655 1Z" stroke="#8FC7B8" />
                                            <foreignObject width="361" height="59">
                                                <input onChange={handleInputChange} className="input" value={formData.email} type="email" name="email" required />
                                            </foreignObject>
                                        </svg>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <label className="labelOver">Mobile number</label>
                                            <svg id="svg" xmlns="http://www.w3.org/2000/svg" width="361" height="59" viewBox="0 0 361 59" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.82791 1H355.172C357.828 1 360 3.40851 360 6.3532V52.6468C360 55.5915 357.828 58 355.172 58H5.82791C3.17276 58 1 55.5915 1 52.6468V6.3532C1 3.40916 3.17218 1 5.82791 1Z" fill="white" stroke="rgb(143, 199, 184)" strokeWidth="1.5" />
                                                <foreignObject id="svg" width="361" height="59">
                                                    <PhoneInput
                                                        defaultCountry="us"
                                                        value={phone}
                                                        onChange={(phone) => setPhone(phone)}
                                                    />
                                                    <input className="react-international-phone-input react-international-phone-input-container" id="phone-input" type="tel" name="phone" maxLength="10" placeholder="Enter Your Phone Number" required />
                                                </foreignObject>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <hr className="linebrake" />

                                <div className="rapText header">Payment</div>

                                <div className="col">
                                    <div className="dotcredit">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                            <circle cx="8.5" cy="9.40869" r="8" fill="white" stroke="#DFE3E5" />
                                            <circle cx="8.5" cy="9.40869" r="5.5" fill="#74DC6B" />
                                        </svg>
                                        <label className="labelOver credit">Credit Card</label>
                                    </div>
                                </div>

                                <div className="row mb-4 credit">

                                    <div className="col">
                                        <label className="labelOver">Name on card</label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="402" height="59" viewBox="0 0 402 59" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.37929 1.40869H395.621C398.58 1.40869 401 3.8172 401 6.76189V53.0555C401 55.9995 398.58 58.4087 395.621 58.4087H6.37929C3.4209 58.4087 1 56.0002 1 53.0555V6.76189C1 3.8172 3.42025 1.40869 6.37929 1.40869Z" stroke="#8FC7B8" />
                                            <foreignObject width="401" height="59">
                                                <input onChange={handleInputChange} className="input" value={formData.creditName} type="text" name="creditName" required />
                                            </foreignObject>
                                        </svg>
                                    </div>
                                    <div className="col">
                                        <label className="labelOver">Experation</label>
                                        <div className="iv-icon dropdowns">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="90" height="59" viewBox="0 0 90 59" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.18344 1.40869H87.8166C88.4675 1.40869 89 3.8172 89 6.76189V53.0555C89 55.9995 88.4675 58.4087 87.8166 58.4087H2.18344C1.5326 58.4087 1 56.0002 1 53.0555V6.76189C1 3.8172 1.53246 1.40869 2.18344 1.40869Z" stroke="#8FC7B8" />
                                                <foreignObject width="361" height="59">

                                                    <select className="input" onChange={handleInputChange} type="text" name="experationMonth" id="experationMonth" required>
                                                        <option value=""></option>
                                                        <option value="01">Jan</option>
                                                        <option value="02">Feb</option>
                                                        <option value="03">Mar</option>
                                                        <option value="04">Apr</option>
                                                        <option value="05">May</option>
                                                        <option value="06">Jun</option>
                                                        <option value="07">Jul</option>
                                                        <option value="08">Aug</option>
                                                        <option value="09">Sep</option>
                                                        <option value="10">Oct</option>
                                                        <option value="11">Nov</option>
                                                        <option value="12">Dec</option>
                                                    </select>

                                                </foreignObject>
                                                <svg className="input dropdown" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" x="65%" y="30%"><path d="M6 9L12 15L18 9" x="50%" y="50%" stroke="#AAAAAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                            </svg>

                                        </div>
                                        <svg className="experationYear" xmlns="http://www.w3.org/2000/svg" width="127" height="59" viewBox="0 0 127 59" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M2.68103 1.40869H124.319C125.244 1.40869 126 3.8172 126 6.76189V53.0555C126 55.9995 125.244 58.4087 124.319 58.4087H2.68103C1.75653 58.4087 1 56.0002 1 53.0555V6.76189C1 3.8172 1.75633 1.40869 2.68103 1.40869Z" stroke="#8FC7B8" />
                                            <foreignObject width="361" height="59">
                                                <select onChange={handleInputChange} className="input" id="experationYear" type="text" name="experationYear" required>
                                                    <option value=""></option>
                                                    <option value="2024">2024</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2027">2027</option>
                                                    <option value="2028">2028</option>
                                                    <option value="2029">2029</option>
                                                    <option value="2030">2030</option>
                                                    <option value="2031">2031</option>
                                                    <option value="2032">2032</option>
                                                    <option value="2033">2033</option>
                                                    <option value="2034">2034</option>
                                                    <option value="2035">2035</option>
                                                </select>

                                            </foreignObject>
                                            <svg className="input dropdown" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" x="65%" y="30%"><path d="M6 9L12 15L18 9" x="50%" y="50%" stroke="#AAAAAA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                        </svg>
                                    </div>
                                </div>

                                <div className="row mb-4 credit">
                                    <div className="col">
                                        <label className="labelOver">Credit card number</label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="402" height="59" viewBox="0 0 402 59" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.37929 1.40869H395.621C398.58 1.40869 401 3.8172 401 6.76189V53.0555C401 55.9995 398.58 58.4087 395.621 58.4087H6.37929C3.4209 58.4087 1 56.0002 1 53.0555V6.76189C1 3.8172 3.42025 1.40869 6.37929 1.40869Z" stroke="#8FC7B8" />
                                            <foreignObject width="401" height="59">
                                                <input onChange={handleInputChange} className="input" value={formData.creditNumber} type="text" name="creditNumber" required />
                                            </foreignObject>
                                        </svg>
                                    </div>
                                    <div className="col">
                                        <label className="labelOver">CVV</label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="59" viewBox="0 0 200 59" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M3.66275 1.40869H196.337C197.802 1.40869 199 3.8172 199 6.76189V53.0555C199 55.9995 197.802 58.4087 196.337 58.4087H3.66275C2.19835 58.4087 1 56.0002 1 53.0555V6.76189C1 3.8172 2.19802 1.40869 3.66275 1.40869Z" stroke="#8FC7B8" />
                                            <foreignObject width="361" height="59">
                                                <input onChange={handleInputChange} className="input" value={formData.cvv} type="text" name="cvv" required />
                                            </foreignObject>
                                        </svg>
                                    </div>
                                </div>

                                <button className="paybtn" id="sumbitBtn" type="submit">
                                    Make Payment
                                </button>

                            </form>
                        </div>
                    </div>
                </div >
                <PaymentPage />
            </div >


        </>
    );


}




