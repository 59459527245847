import React from 'react';
import 'react-international-phone/style.css';
import '../css/signForm.css';
import '../css/headerText.css';
import HomeOrderPage from './HomeOrderPage';
import Backimg from '../assets/ecom2.png';
import HeaderText from './headerText';

export default function Shop(props) {
    return (
        <>

            <div className="wrapPageSignUp" style={{
                backgroundImage: `url(${Backimg})`
            }}>
                <HeaderText />
                <HomeOrderPage />
            </div >

        </>
    );
}