import React from "react";

export default function Product(props) {

    const { product, onAdd } = props;
    const navigateToPaymentPage = () => {
        setTimeout(() => {
            document.getElementById("checkoutbtn").click();
        }, 500);
    };
    const addProduct = () => {
        onAdd(product);
    };

    return (
        <div>
            <div className="rap-item-block">
                <div className="rap-img-product">
                    <img className="small" src={product.image} alt={product.name} />
                </div>
                <div className="rap-text">
                    <div className="item-text1">{product.name}</div>
                    <div className="item-text2">{product.description}</div>
                    <div className="item-text3">${product.price}</div>
                </div>
            </div>

            <div className="rap-btn">
                <button className="btn-products" onClick={event => {
                    addProduct();
                    navigateToPaymentPage();
                }}>Buy</button>
            </div>
        </div>
    );
}
