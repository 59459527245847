import Main from "./Main";
import Basket from "./Basket";
import PaymentPage from "./PaymentPage";
import data from "./data";
import { useState } from "react";
import { Routes, Route } from "react-router-dom";

function HomeOrderPage() {
    const { products } = data;
    const [cartItems, setCartItems] = useState([]);

    // -------------Add and Remove product functionality------------------------------------------
    const onAdd = (product) => {
        const exist = cartItems.find((x) => x.id === product.id);
        if (exist) {
            setCartItems(
                cartItems.map((x) =>
                    x.id === product.id ? { ...exist, qty: exist.qty + 1 } : x
                )
            );
        } else {
            setCartItems([...cartItems, { ...product, qty: 1 }]);
        }
    };

    const onRemove = (product) => {
        const exist = cartItems.find((x) => x.id === product.id);
        if (exist.qty === 1) {
            setCartItems(cartItems.filter((x) => x.id !== product.id));
        } else {
            setCartItems(
                cartItems.map((x) =>
                    x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
                )
            );
        }
    };
    // -------------------------------------------------------------------------------------------

    return (
        <div className="App">
            <div className="row">
                <Main onAdd={onAdd} products={products} />
                <Basket
                    onAdd={onAdd}
                    onRemove={onRemove}
                    cartItems={cartItems}
                ></Basket>
            </div>
            <Routes>
                <Route path="/paymentpage" element={<PaymentPage />} />
            </Routes>
        </div>
    );
}

export default HomeOrderPage;

