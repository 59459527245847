import image1 from '../assets/unsplash1.png'
import image2 from '../assets/unsplash2.png'
import image3 from '../assets/unsplash3.png'
import image4 from '../assets/unsplash4.png'

const data = {
    products: [
        {
            id: "1",
            name: "Flex Experience",
            discription: "Women’s Road Running Shoes",
            price: 229,
            size: 40,
            image: image4
        },
        {
            id: "2",
            name: "React Vision",
            discription: "Men's Running Shoes",
            price: 319,
            size: 42,
            image: image3
        },
        {
            id: "3",
            name: "Air Jordan 1 Low SE Craft",
            discription: "Men's Shoes",
            price: 259,
            size: 43,
            image: image2
        },
        {
            id: "4",
            name: "Air Jordan 1 Low SE Craft",
            discription: "Men's Shoes",
            price: 259,
            size: 42,
            image: image1
        },
    ]
};
export default data;
