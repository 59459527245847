import React from "react";
import { useLocation } from "react-router-dom";

export default function PaymentPage(props) {
    const location = useLocation();
    const data = location.state;
    return (
        <>
            <div className="main-rap">
                {/* <div className="col-md-4 mb-4"> */}
                <div className="rap-summary">

                    <div className="rapText sum">Order Summary</div>

                    <div className="rapsum">
                        <ul className="listtext">
                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                                Subtotal
                                <span>${data.totalPrice}</span>

                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                Estimated Delivery & Handling
                                <span>${Math.floor(data.totalPrice * 0.2)}</span>
                            </li>
                            <hr className="list-hr" />
                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                                Total
                                <span>${Math.floor(data.totalPrice * 0.2) + (data.totalPrice)}</span>
                            </li>
                            <hr className="list-hr" />
                        </ul>

                        <div className="rap-item-block-checkout">
                            <img className="imgCheckout" src={data.cartItems[0].image} alt={data.cartItems[0].name} width="180" height="180"></img>
                            <div className="rap-text">
                                <div className="item-text1">{data.cartItems[0].name}</div>
                                <div className="item-text2">{data.cartItems[0].discription}</div>
                                <div className="item-text3">Size: {data.cartItems[0].size}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

